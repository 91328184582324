<template>
  <v-expansion-panel>
    <template>
      <v-expansion-panel-header class="mt-2">
        Asignación de cuentas
        <template v-slot:actions>
          <v-btn class="mr-5" @click.native.stop="reload()" icon small>
            <v-icon color="primary">mdi-reload</v-icon>
          </v-btn>
          <v-btn
            @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })"
            icon
            small
          >
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab
            href="#tab-1"
            v-if="$store.getters['rolesUser/getCustomRole'](174)"
          >
            Productos
            <v-icon>mdi-package-variant</v-icon>
          </v-tab>
          <v-tab
            href="#tab-2"
            v-if="$store.getters['rolesUser/getCustomRole'](175)"
          >
            Servicios
            <v-icon>mdi-cogs</v-icon>
          </v-tab>
          <v-tab
            href="#tab-3"
            v-if="$store.getters['rolesUser/getCustomRole'](176)"
          >
            Parametrizacion <br />General
            <v-icon>mdi-account</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">
            <v-card class="pa-1 rounded-lg">
              <v-card-text class="white">
                <AllocationProducts
                  :win="win"
                  :key="key"
                  :datafilter="filterData"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card class="pa-1 rounded-lg">
              <v-card-text class="white">
                <AllocationServices
                  :win="win"
                  :key="key"
                  :datafilter="filterData"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-3">
            <v-card flat class="pa-1 rounded-lg">
              <v-card-text class="white">
                <AccountParams :win="win" :key="key" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-expansion-panel-content>
    </template>
  </v-expansion-panel>
</template>

<script>
//import {mapState} from 'vuex';
import AllocationProducts from "../partial/AllocationProducts.vue";
import AllocationServices from "../partial/AllocationServices.vue";
import AccountParams from "../partial/AccountParams.vue";

export default {
  name: "AccountAllocation",
  components: {
    AllocationProducts,
    AllocationServices,
    AccountParams,
  },
  props: ["win"],
  data() {
    return {
      panel: 0,
      tab: null,
      key: 0,
      filterData: {
        Families: [],
        Categories: [],
        SubCategories: [],
        Warehouses: [],
      },
    };
  },
  computed: {
    /*...mapState('customerData', {
                tab: 'tab-1'
            }),*/
  },
  mounted() {
    this.getFilterData();
  },
  methods: {
    async getFilterData() {
      try {
        const [families, categories, subs] = await Promise.all([
          this.$API.itemtypes.getFamilies(),
          this.$API.itemtypes.getCategories(),
          this.$API.itemtypes.getSubs(),
        ]);

        this.filterData.Families = families.map((fam) => ({
          name: fam.name,
          value: fam.id,
        }));
        this.filterData.Categories = categories.map((cat) => ({
          name: cat.name,
          value: cat.id,
          parent_id: cat.id_family,
        }));
        this.filterData.SubCategories = subs.map((sub) => ({
          name: sub.name,
          value: sub.id,
          parent_id: sub.id_cat,
        }));

        this.key++;
      } catch (error) {
        // Manejar errores aquí
        console.error("Error al obtener datos:", error);
      }
    },
    reload() {
      this.key++;
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
        //alert(this.$store.getters.getScrollTop)
      }, 300);
    },
  },
};
</script>

<style scoped></style>
