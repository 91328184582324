<template>
    <div>
        <block v-if="loading"/>
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end align-center">
                <v-btn
                    class="btnsave mr-1"
                    small
                    v-if="$store.getters['rolesUser/getCustomRole'](194)"
                    @click.prevent="updateAcountConfig"
                >
                    <v-icon>
                        mdi-content-save
                    </v-icon>
                   
                </v-btn>
            </div>
        </v-card>
        <div>
            <v-tabs
                v-model="panel"
                background-color="transparent"
                grow
                
                >
            
                <v-tab
                    v-for="tab in showTabs"
                    :key="tab.Type"
                    :href="'#tab-'+tab.Type"
                    
                    >
                    {{ tab.Name }}
                </v-tab>

            </v-tabs>
            <v-tabs-items v-model="panel">
                <v-tab-item
                    v-for="tab in showTabs"
                    :value="'tab-'+tab.Type"
                    :key="tab.Type"
                    >
                    <template v-if="tab.Type === '2' ">
                        <div class="groupbox" >
                            <v-radio-group  v-model="tab.group" row>
                                <v-radio
                            
                                    label="Por Metodo de pago"
                                    value="1"
                                ></v-radio>
                                <v-radio
                            
                                    label="Por Estado de la factura"
                                    value="2"
                                ></v-radio>
                            </v-radio-group>
                            <div  v-for="data,index in tab.childs" :key="index"  >
                                <v-row v-if="data.box && tab.group == data.box" >
                                    <v-col cols="12" md="5">
                                        <v-card-title>
                                            {{ data.Name }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col class="mt-2" cols="12" md="5">
                                        <v-text-field
                                            v-model="data.value"
                                            :ref="data.Name"
                                            label="Numero de Cuenta"
                                            outlined
                                            dense
                                            @input="validateNumeber"
                                            ></v-text-field>
                                    </v-col>
                                    <v-col class="mt-2" cols="12" md="2">
                                        <v-btn
                                            class="btn-add"
                                            v-if="$store.getters['rolesUser/getCustomRole'](tab.id_update)"
                                            small
                                            @click="showModel = true; itemSelected = data"
                                            >
                                            <v-icon>
                                                mdi-magnify
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </template>
                    <v-row class="mt-3" v-for="data,index in tab.childs" :key="index">
                        
                        <template v-if="!data.box">
                            <v-col cols="12" md="5">
                                <v-card-title>
                                    {{ data.Name }}
                                </v-card-title>
                            </v-col>
                            <v-col class="mt-2" cols="12" md="5">
                                <v-text-field
                                    v-model="data.value"
                                    :ref="data.Name"
                                    label="Numero de Cuenta"
                                    outlined
                                    dense
                                    @input="validateNumeber"
                                    ></v-text-field>
                            </v-col>
                            <v-col class="mt-2" cols="12" md="2">
                                <v-btn
                                    class="btn-add"
                                    v-if="$store.getters['rolesUser/getCustomRole'](tab.id_update)"
                                    small
                                    @click="showModel = true; itemSelected = data"
                                    >
                                    <v-icon>
                                        mdi-magnify
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </template>
                        
                    </v-row>
                    
                   
                </v-tab-item>
                
            </v-tabs-items>
        </div>
        <v-dialog v-model="showModel" width="60%">
                <v-card>
                  <AccountingItemForm :win="win"
                  v-on:sendSelection="reaciveSelection"
                  />
                </v-card>
        </v-dialog>

        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        />
    </div>
</template>

<script>
    //import {mapState} from 'vuex';
    import Alerts from "@/components/Alerts";
    import AccountingItemForm from './AccountingItemForm.vue';
    import Block from "@/components/Block.vue";
    export default {
        name: "AccountParams",
        components: {
            AccountingItemForm,
            Alerts,
            Block
        },
        props: ['win'],
        data() {
            return {
                DataTable: [],
                loading: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                panel: 0,
                showModel: false,
                tab: null,
                itemSelected: null,
                key: 0,
                Accounts: [
                    {
                        Type: '1',
                        Name: 'Cuenta para pago o provision',
                        value: null,
                        dbName: 'purchase_id_account'
                    },
                    {
                        Type: '1',
                        Name: 'Cuenta para credito fiscal o compras locales ',
                        value: null,
                        dbName: 'purchase_iva_id_account'
                    },
                    {
                        Type: '1',
                        Name: 'Cuenta para Percerpciones Iva',
                        value: null,
                    },
                    {
                        Type: '1',
                        Name: 'Cuenta para Retencion Renta',
                        value: null,
                    },
                    {
                        Type: '2',
                        Name: 'Cuenta para Ventas al contado',
                        value: null,
                        dbName : 'sales_id_account_counted',
                        box: '2'
                    },
                    {
                        Type: '2',
                        Name: 'Cuenta para Ventas al credito',
                        value: null,
                        dbName : 'sales_id_account_credit',
                        box: '2'
                    },
                    {
                        Type: '2',
                        Name: 'Cuenta Debito Fiscal Contribuyente',
                        value: null,
                        dbName : 'taxpayer_tax_debit_id_account'
                    },
                    {
                        Type: '2',
                        Name: 'Cuenta Debido Fiscal Consumidor Final',
                        value: null,
                        dbName : 'final_customer_tax_debit_id_account'
                    },
                    {
                        Type: '2',
                        Name: 'Cuenta Retencion Iva',
                        value: null,
                        dbName: 'withholdings_id_account'
                    },
                    {
                        Type: '2',
                        Name: 'Cuenta ventas no Sujetas',
                        value: null,
                    },
                    {

                        Type: '3',
                        Name: 'Cuenta para pagos de clientes',
                        value: null,
                    }

                ],
                TabsToShow:[
                    {
                        Type: '1',
                        id_view: 188,
                        id_update: 189,
                        Name: 'Cuentas Para Compras',
                        childs:[]
                      
                    },
                    {
                        Type: '2',
                        id_view: 190,  
                        id_update: 191,
                        Name: 'Cuentas para Ventas',
                        childs:[],
                        group: '1'
                       
                    },
                    {
                        Type: '3',
                        id_view: 192,
                        id_update: 193,
                        Name: 'Otras Cuentas',
                        childs:[]
                      
                    }
                ],
                AcountList: [],
                Payments: []
            }
        },
        computed:{
            showTabs(){
             
                return this.TabsToShow.filter(tab => this.$store.getters['rolesUser/getCustomRole'](tab.id_view))
            }
            
        },
        methods: {
            async getAllAcounts(){
                try {
                    const result = await this.$API.branchaccountcatalog.getActiveBranchAccountCatalog();
                    this.AcountList = result.data;
                   
                } catch (error) {
                    console.log(error);
                }
            },
            async getAcountConfig(){
                try{
                    const data = await this.$API.branchaccountconfig.getBranchAccountsConfig()
                    // console.log("config ", data.data);
                    if( !data.data ) {
                        this.DataTable = {
                            ID_Business: JSON.parse(localStorage.getItem('user')).businessid,
                            ID_Branch: JSON.parse(localStorage.getItem('user')).branch,
                        }
                    } else {
                        this.DataTable = data.data.config;
                        this.Payments = data.data.paymentmethods;
                    }
                }
                catch(error){
                    console.log(error);
                }
            },
            closeAlert() {
                this.alert.show = false;

            },
            acceptAlert() {
                this.alert.show = false;

      
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            async updateAcountConfig(){
                try{
                    this.loading = true;
                    const body = {
                        branch_account_config: {},
                        payment_method_config: []
                    };
                    for (const element of this.TabsToShow) {
                        for (const child of element.childs) {
                            if (child.dbName) {
                                console.log(child);
                                body.branch_account_config[child.dbName] = child.id || null;
                            }
                            if (child.box === '1') {
                                body.payment_method_config.push({
                                    id_payment_method: child.dbStr,
                                    id_account: child.id
                                });
                            }
                        }
                    }


                    await this.$API.branchaccountconfig.UpdateBranchAccountsConfig(body)
                    this.loading = false;
                    this.setupComponent();
                    this.showAlert('success','Exito','Se ha actualizado la configuracion de cuentas');
                }
                catch(error){
                    this.loading = false;
                    console.log(error);
                }
            },
            AssignChilds(){

                this.Accounts.forEach(element => {
                    // Verificar si el elemento actual tiene la propiedad 'dbName'
                    // console.log(element.dbName);
                    // console.log(this.DataTable);
                    if(element.dbName && element.dbName in this.DataTable) {
                        
                        this.AcountList.forEach(account => {
                            if(account.id_account == this.DataTable[element.dbName]){
                                element.id = account.id_account;
                                element.value = account.account_code;
                            }
                           
                        });
                        
                        
                    }
                    if(element.dbStr ){
                        
                        const reg = this.Payments.find(x=>x.InvoicePaymentType == element.dbStr);
                        if(reg){
                            this.AcountList.forEach(account => {
                                if(account.id_account == reg.id_account){
                                    element.id = account.id_account;
                                    element.value = account.account_code;
                                }
                            });
                        }
                    }
                });


                this.TabsToShow.forEach(element => {
                    element.childs = this.Accounts.filter(x=>x.Type == element.Type)
                });
            },
            reaciveSelection(data){
                this.showModel = false;
                console.log(data);
                this.TabsToShow.forEach(element => {
                    element.childs.forEach(child => {

                        if(child.Name == this.itemSelected.Name){
                            child.id = data.id_account;
                            child.value = data.account_code

                        }
                    });
                });
            },
            async setupComponent() {
                try {
                    this.getPaymethods();
                    await this.getAllAcounts();
                    await this.getAcountConfig();
                    await this.AssignChilds();
                } catch (error) {
                    console.log(error);
                }

            },
            getPaymethods(){
               const data =  this.$store.getters['getCatalogMH']('FormaPago');
               if (data.length > 0) {
                   data.forEach(element => {

                        const Current = this.Accounts.find(x=>x.dbStr == element.Valores);
                        if(!Current) 
                       this.Accounts.push({
                           Type: '2',
                           Name: 'Cuenta para ' + element.Valores,
                           value: null,
                           box: '1',
                           dbStr: element.Valores,

                       });  
                   });
                   
               }
            },
            async validateNumeber(){
               
                for(let i = 0; i < this.TabsToShow.length; i++ ){
                    for(let j = 0; j < this.TabsToShow[i].childs.length; j++){
                        
                        
                           
                            await this.$nextTick(()=>{
                                
                                this.TabsToShow[i].childs[j].value = this.TabsToShow[i].childs[j].value.replace(/[^0-9]/g, "");
                
                            })
                        }
                    }
                


                
                
                
            },
           
        },
        mounted() {
            this.setupComponent();
            
        },

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}

.groupbox {
  border-width: 2px; /* Grosor del borde */
  border-style: solid; /* Estilo del borde, en este caso sólido */
  border-color: #000; /* Color del borde, en este caso negro */
  border-radius: 15px;
  padding: 16px;
}

.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>